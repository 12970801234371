'use client';

import Script from 'next/script';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import sbjs from 'sourcebuster';
import { useLocale } from 'next-intl';

import RollbarResource from './RollbarResource';
import PreloadResources from './PreloadResources';
import { isProduction } from '../helpers/environment';
import { UA } from '../constants/i18n';

/* eslint-disable max-len */
export default function CustomScripts() {
    const locale = useLocale();
    const pathname = usePathname();
    const [chatsVisible, setChatsVisible] = useState(false);

    // Поскольку next ведется себя как SPA, нужно сказать что меняется страница
    const onRouteChange = (url) => {
        /* eslint-disable-next-line no-undef */
        if (window.ga) ga('send', 'pageview', url);
        /* eslint-disable-next-line no-undef, radix */
        if (window.Intercom) Intercom('update', { last_request_at: parseInt((new Date()).getTime() / 1000) });

        if (window._hsb) { // eslint-disable-line no-underscore-dangle
            window._hsq.push(['setPath', url]); // eslint-disable-line no-underscore-dangle
            window._hsq.push(['trackPageView']); // eslint-disable-line no-underscore-dangle
        }
    };

    const showChats = () => {
        setChatsVisible(true);
        document.removeEventListener('scroll', showChats);
    };

    const intercomSettings = {
        app_id: ['ua', 'ru'].includes(locale)
            ? process.env.NEXT_PUBLIC_INTEROM_RU
            : process.env.NEXT_PUBLIC_INTEROM_EN,
        language_override: locale === UA ? 'uk' : locale,
    };

    useEffect(() => {
        setTimeout(showChats, 4000);

        window.hsConversationsSettings = {
            loadImmediately: false,
        };

        document.addEventListener('scroll', showChats);

        sbjs.init({
            domain: 'joinposter.com',
            isolate: true,
        });

        // Выполняется при размонтировании компоненты
        return () => document.removeEventListener('scroll', showChats);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onRouteChange(pathname);
    }, [pathname]);

    return (
        <>
            <PreloadResources/>

            {isProduction() && (
                <>
                    {/* GTM */}
                    <noscript>
                        <iframe
                            src="https://www.googletagmanager.com/ns.html?id=GTM-TG4MTPS"
                            height="0"
                            width="0"
                            style={{ display: 'none', visibility: 'hidden' }}
                        />
                    </noscript>
                    <Script
                        id="gtm-script"
                        strategy="lazyOnload"
                        async
                        dangerouslySetInnerHTML={{
                            __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-TG4MTPS');
                    `,
                        }}
                    />
                </>
            )}

            {chatsVisible && isProduction() && (
                <>
                    <Script
                        id="intercom-script"
                        strategy="lazyOnload"
                        async
                        dangerouslySetInnerHTML={{
                            __html: `
                                window.intercomSettings = ${JSON.stringify(intercomSettings)};

                                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');
                                ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];
                                i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');
                                s.type='text/javascript';s.async=true;
                                s.src='https://widget.intercom.io/widget/${intercomSettings.app_id}';
                                var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};
                                if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);
                                }else{w.addEventListener('load',l,false);}}})();
                            `,
                        }}
                    />
                </>
            )}

            {isProduction() && (
                <Script
                    async defer src="https://js-eu1.hs-scripts.com/145006115.js"
                    id="hs-script-loader"
                />
            )}

            {isProduction() && <RollbarResource/>}
        </>
    );
}
